import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ProjectDto } from "../../api/projectSpaceApiTypes";

interface FlyoutState {
  value: boolean;
  data: ProjectDto | null;
}

const initialState: FlyoutState = {
  value: false,
  data: null,
};

export const flyoutSlice = createSlice({
  name: "flyoutVisible",
  initialState,
  reducers: {
    showFlyout: (
      state,
      action: PayloadAction<ProjectDto | undefined | null>
    ) => {
      state.value = true;
      state.data = action.payload === undefined ? null : action.payload;
    },
    hideFlyout: (state) => {
      state.value = false;
      state.data = null;
    },
  },
});

export const { showFlyout, hideFlyout } = flyoutSlice.actions;

export const getFlyoutState = (state: RootState) => state.flyoutVisible.value;
export const getFlyoutData = (state: RootState) => state.flyoutVisible.data;

export default flyoutSlice.reducer;
