import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Header } from "../../components/Header";
import {
  EuiListGroup,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
} from "@elastic/eui";
import { TagList } from "./TagList";
import { Flyout } from "../../components/flyout/Flyout";
import { LoadingPage } from "../../components/loading/LoadingPage";
import {
  CourseDto,
  LanguageDto,
  ProjectDto,
  TagDto,
} from "../../api/projectSpaceApiTypes";
import { BADGE, fetchProjectsPromise } from "../../api/projectSpaceApi";
import { ensure } from "../../utils/utils";

export interface ProjectsPerLanguageDto {
  type_: LanguageDto;
  projects: ProjectDto[];
}

export interface ProjectsPerTagDto {
  type_: TagDto;
  projects: ProjectDto[];
}

export interface ProjectsPerCourseDto {
  type_: CourseDto;
  projects: ProjectDto[];
}

export const TagsRoute: React.FC = () => {
  const [projects, setProjects] = useState<ProjectDto[]>([]);
  const [projectsPerLanguage, setProjectsPerLanguage] = useState<
    ProjectsPerLanguageDto[]
  >([]);
  const [projectsPerTag, setProjectsPerTag] = useState<ProjectsPerTagDto[]>([]);
  const [projectsPerCourse, setProjectsPerCourse] = useState<
    ProjectsPerCourseDto[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const projectsResult = await fetchProjectsPromise();

      if (projectsResult) {
        setProjects(projectsResult);
        const projectsPerL: ProjectsPerLanguageDto[] = [];
        const projectsPerT: ProjectsPerTagDto[] = [];
        const projectsPerC: ProjectsPerCourseDto[] = [];

        projectsResult.forEach((project) => {
          if (project.fields.languages)
            project.fields.languages.forEach((lang) => {
              if (
                !projectsPerL.some(
                  (i) => i.type_.fields.language === lang.fields.language
                )
              ) {
                projectsPerL.push({
                  type_: lang,
                  projects: [],
                });
              }
              ensure(
                projectsPerL.find(
                  (i) => i.type_.fields.language === lang.fields.language
                )
              ).projects.push(project);
            });

          if (project.fields.tags) {
            project.fields.tags.forEach((tag) => {
              if (
                !projectsPerT.some(
                  (i) => i.type_.fields.name === tag.fields.name
                )
              ) {
                projectsPerT.push({
                  type_: tag,
                  projects: [],
                });
              }
              ensure(
                projectsPerT.find(
                  (i) => i.type_.fields.name === tag.fields.name
                )
              ).projects.push(project);
            });
          }

          if (project.fields.course)
            project.fields.course.forEach((course) => {
              if (
                !projectsPerC.some(
                  (i) => i.type_.fields.name === course.fields.name
                )
              ) {
                projectsPerC.push({
                  type_: course,
                  projects: [],
                });
              }
              ensure(
                projectsPerC.find(
                  (i) => i.type_.fields.name === course.fields.name
                )
              ).projects.push(project);
            });
        });

        setProjectsPerLanguage(projectsPerL);
        setProjectsPerTag(projectsPerT);
        setProjectsPerCourse(projectsPerC);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Header />
      <EuiPage>
        <EuiPageBody component="div">
          {isLoading ? (
            <LoadingPage />
          ) : (
            <>
              <EuiPageHeader>
                <EuiPageHeaderSection>
                  <EuiTitle size="l">
                    <h1>Search</h1>
                  </EuiTitle>
                </EuiPageHeaderSection>
              </EuiPageHeader>
              <EuiPageContent>
                {/*<EuiPageContentHeader>*/}
                {/*  <EuiPageContentHeaderSection>*/}
                {/*    <EuiTitle>*/}
                {/*      <h2>Christian Dalton</h2>*/}
                {/*    </EuiTitle>*/}
                {/*  </EuiPageContentHeaderSection>*/}
                {/*</EuiPageContentHeader>*/}
                <EuiPageContentBody>
                  <EuiTitle>
                    <h3>Languages</h3>
                  </EuiTitle>
                  <EuiListGroup flush={false}>
                    <TagList
                      tagType={BADGE.LANGUAGE}
                      items={projectsPerLanguage}
                    />
                  </EuiListGroup>
                  <EuiTitle>
                    <h3>Courses</h3>
                  </EuiTitle>
                  <EuiListGroup flush={false}>
                    <TagList tagType={BADGE.COURSE} items={projectsPerCourse} />
                  </EuiListGroup>
                  <EuiTitle>
                    <h3>Other Tags</h3>
                  </EuiTitle>
                  <EuiListGroup flush={false}>
                    <TagList tagType={BADGE.TAG} items={projectsPerTag} />
                  </EuiListGroup>
                </EuiPageContentBody>
              </EuiPageContent>
            </>
          )}
        </EuiPageBody>
      </EuiPage>
      <Flyout />
    </>
  );
};
