import {
  EuiButton,
  EuiCard,
  EuiEmptyPrompt,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
} from "@elastic/eui";
import React, { MouseEvent } from "react";
import { v4 } from "uuid";
import { TagPopover } from "../../components/tooltip/TagPopover";
import { showFlyout } from "../../features/redux/flyoutSlice";
import {
  getProjectsLoadedCount,
  incrementByAmount,
} from "../../features/redux/projectsLoadedSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  InProductionTooltip,
  StarredTooltip,
} from "../../components/tooltip/tooltips";
import { AutoBadge } from "../../components/tooltip/AutoBadge";
import {
  CourseDto,
  LanguageDto,
  ProjectDto,
  TagDto,
} from "../../api/projectSpaceApiTypes";

interface ProjectCardsProps {
  projects: ProjectDto[];
}

export const ProjectCards: React.FC<ProjectCardsProps> = ({ projects }) => {
  const dispatch = useDispatch();
  const itemCount: number = useSelector(getProjectsLoadedCount);

  const handleCardClick = (e: MouseEvent, item: ProjectDto) => {
    dispatch(showFlyout(projects.find((o) => o.sys.id === item.sys.id)));
  };

  const cards = projects.slice(0, itemCount).map((item, index) => {
    let badges: (LanguageDto | CourseDto | TagDto)[] = [];
    let hiddenBadges: (LanguageDto | CourseDto | TagDto)[] = [];

    let langCount: number = 0;
    let courseCount: number = 0;
    let tagCount: number = 0;

    while (badges.length < 3) {
      if (item.fields.languages && langCount < item.fields.languages.length) {
        badges.push(item.fields.languages[langCount]);
        langCount++;
      } else if (
        item.fields.course &&
        courseCount < item.fields.course.length
      ) {
        badges.push(item.fields.course[courseCount]);
        courseCount++;
      } else if (item.fields.tags && tagCount < item.fields.tags.length) {
        badges.push(item.fields.tags[tagCount]);
        tagCount++;
      } else {
        break;
      }
    }

    if (item.fields.languages) {
      for (langCount; langCount < item.fields.languages.length; langCount++) {
        hiddenBadges.push(item.fields.languages[langCount]);
      }
    }

    if (item.fields.course) {
      for (
        courseCount;
        courseCount < item.fields.course.length;
        courseCount++
      ) {
        hiddenBadges.push(item.fields.course[courseCount]);
      }
    }

    if (item.fields.tags) {
      for (tagCount; tagCount < item.fields.tags.length; tagCount++) {
        hiddenBadges.push(item.fields.tags[courseCount]);
      }
    }

    return (
      <EuiFlexItem key={index}>
        <EuiCard
          className={"euiCard--isClickable"}
          icon={
            <div onClick={(e: MouseEvent) => handleCardClick(e, item)}>
              <EuiIcon
                size="xl"
                type={
                  item.fields.logo ? item.fields.logo.fields.file.url : "empty"
                }
              />
            </div>
          }
          title={
            <div onClick={(e: MouseEvent) => handleCardClick(e, item)}>
              {item.fields.title}
            </div>
          }
          description={
            <span onClick={(e: MouseEvent) => handleCardClick(e, item)}>
              {item.fields.shortDescription}
            </span>
          }
          footer={
            <EuiFlexGroup
              alignItems={"center"}
              justifyContent="flexEnd"
              gutterSize={"xs"}
              responsive={false}
            >
              <EuiFlexGroup
                alignItems={"center"}
                justifyContent="flexStart"
                gutterSize={"none"}
                responsive={false}
              >
                {item.fields.starred ? <StarredTooltip /> : null}
                {item.fields.production ? <InProductionTooltip /> : null}
              </EuiFlexGroup>

              {badges.map((lang: LanguageDto | CourseDto | TagDto, index) => {
                return (
                  <AutoBadge
                    key={v4()}
                    badge={lang}
                    popoverPosition={"bottom"}
                  />
                );
              })}

              {hiddenBadges.length > 0 ? (
                <TagPopover badges={hiddenBadges} />
              ) : null}
            </EuiFlexGroup>
          }
        />
      </EuiFlexItem>
    );
  });

  return (
    <>
      {projects.length === 0 ? (
        <EuiEmptyPrompt title={<h3>No projects found</h3>} />
      ) : (
        <EuiFlexGrid gutterSize={"s"} columns={3}>
          {cards}
        </EuiFlexGrid>
      )}

      <EuiSpacer />
      {itemCount >= projects.length ? null : (
        <EuiFlexGroup alignItems={"center"} justifyContent={"spaceAround"}>
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={() => {
                dispatch(incrementByAmount(3));
              }}
            >
              Load More
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </>
  );
};
