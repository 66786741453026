import { EuiFlexItem, EuiIcon, EuiToolTip } from "@elastic/eui";
import React from "react";

export const InProductionTooltip: React.FC = () => {
  return (
    <EuiFlexItem grow={false}>
      <EuiToolTip position={"bottom"} content={"In production!"}>
        <EuiIcon type={"globe"} color="#006BB4" />
      </EuiToolTip>
    </EuiFlexItem>
  );
};

export const StarredTooltip: React.FC = () => {
  return (
    <EuiFlexItem grow={false}>
      <EuiToolTip position={"bottom"} content={"Worth checking out!"}>
        <EuiIcon type={"starFilled"} color="#F5A700" />
      </EuiToolTip>
    </EuiFlexItem>
  );
};
