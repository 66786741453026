import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import projectsReducer from "../redux/projectsLoadedSlice";
import flyoutReducer from "../redux/flyoutSlice";
import themeReducer from "../redux/themeSlice";

export const store = configureStore({
  reducer: {
    projectsLoaded: projectsReducer,
    flyoutVisible: flyoutReducer,
    theme: themeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
