import React, { useState } from "react";

import {
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderLogo,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiIcon,
} from "@elastic/eui";
import { EuiHeaderProps } from "@elastic/eui/src/components/header/header";
import { useHistory } from "react-router-dom";
import { routes } from "./types/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  getThemeState,
  Theme,
  toggleTheme,
} from "../features/redux/themeSlice";

export const Header: React.FC = () => {
  const [position, setPosition] =
    useState<EuiHeaderProps["position"]>("static");
  const history = useHistory();
  const dispatch = useDispatch();
  const handleThemeIconClick = () => {
    dispatch(toggleTheme());
  };

  return (
    <>
      <EuiHeader position={position} theme={"dark"}>
        <EuiHeaderSection grow={false}>
          <EuiHeaderSectionItem border="right">
            <EuiHeaderLogo
              onClick={() => history.push(routes.INDEX)}
              iconType="/icons/my-icon.svg"
            >
              Christian Dalton
            </EuiHeaderLogo>
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem border="right">
            <EuiHeaderLinks>
              <EuiHeaderLink iconType={"logoGithub"} href={routes.GITHUB}>
                Github
              </EuiHeaderLink>
              <EuiHeaderLink
                iconType={"paperClip"}
                onClick={() => history.push(routes.RESUME)}
              >
                Resume
              </EuiHeaderLink>
              <EuiHeaderLink
                iconType={"user"}
                onClick={() => history.push(routes.CONTACT)}
              >
                Contact
              </EuiHeaderLink>
            </EuiHeaderLinks>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>

        <EuiHeaderSection side="right">
          <EuiHeaderSectionItem>
            <EuiHeaderLink>
              <EuiIcon
                type={"search"}
                onClick={() => history.push(routes.TAGS)}
              />
            </EuiHeaderLink>
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
            <EuiHeaderLink>
              <EuiIcon
                type={
                  useSelector(getThemeState) === Theme.LIGHT ? "moon" : "node"
                }
                onClick={() => {
                  handleThemeIconClick();
                }}
              />
            </EuiHeaderLink>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
    </>
  );
};

// const HeaderSearch = () => {
//
// }
