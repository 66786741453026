import React, { useEffect, useState } from "react";
import {
  EuiAccordion,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { v4 } from "uuid";
import { PublicationDto, UniversityDto } from "../../api/projectSpaceApiTypes";
import {
  fetchDegreesPromise,
  fetchPublicationsPromise,
} from "../../api/projectSpaceApi";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { contentfulTextOptions } from "../../components/ContentfulText";
import { LoadingIndicator } from "../../components/loading/LoadingIndicator";

export const Academics: React.FC = () => {
  const [publications, setPublications] = useState<PublicationDto[]>([]);
  const [universities, setUniversities] = useState<UniversityDto[]>([]);

  const [isPublicationsLoading, setIsPublicationsLoading] =
    useState<boolean>(true);
  const [isDegreesLoading, setIsDegreesLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchPublicationsPromise();
      setPublications(result);
      setIsPublicationsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchDegreesPromise();
      setUniversities(result);
      setIsDegreesLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <EuiTitle>
        <h2>Academics</h2>
      </EuiTitle>
      <EuiPanel>
        <>
          <>
            {isDegreesLoading ? (
              <LoadingIndicator />
            ) : (
              universities.map((item) => {
                return (
                  <>
                    <EuiTitle size={"s"} key={v4()}>
                      <h3 key={v4()}>{item.fields.university.fields.name}</h3>
                    </EuiTitle>
                    {item.fields.degrees.map((d) => {
                      return (
                        <EuiFlexGroup key={v4()} style={{ paddingLeft: "2em" }}>
                          <EuiFlexItem key={v4()}>
                            <EuiText key={v4()}>{d}</EuiText>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      );
                    })}
                  </>
                );
              })
            )}
          </>
          <EuiSpacer size={"s"} />
          <EuiTitle size={"s"}>
            <h3>Publications</h3>
          </EuiTitle>

          {isPublicationsLoading ? (
            <EuiLoadingContent />
          ) : (
            publications.map((item) => {
              return (
                <EuiFlexGroup key={v4()} style={{ paddingLeft: "2em" }}>
                  <EuiFlexItem key={v4()}>
                    <EuiText key={v4()}>{item.fields.authorship}</EuiText>
                    <EuiText style={{ paddingLeft: "2em" }} key={v4()}>
                      {documentToReactComponents(
                        // @ts-ignore
                        item.fields.title,
                        contentfulTextOptions
                      )}
                      {item.fields.paper}
                    </EuiText>
                    <EuiSpacer size={"s"} key={v4()} />
                    <EuiAccordion
                      key={v4()}
                      style={{ paddingLeft: "2em" }}
                      id={item.sys.id}
                      buttonContent={"Abstract"}
                      paddingSize={"l"}
                    >
                      <EuiText key={v4()}>
                        <>
                          {documentToReactComponents(
                            // @ts-ignore
                            item.fields.abstract,
                            contentfulTextOptions
                          )}
                        </>
                      </EuiText>
                    </EuiAccordion>
                  </EuiFlexItem>
                </EuiFlexGroup>
              );
            })
          )}
        </>
      </EuiPanel>
    </>
  );
};
