import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiLoadingContent,
  EuiMarkdownFormat,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import {
  getFlyoutData,
  getFlyoutState,
  hideFlyout,
} from "../../features/redux/flyoutSlice";
import { ImageGallery } from "../ImageGallery/ImageGallery";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { contentfulTextOptions } from "../ContentfulText";
import { CourseBadge } from "../tooltip/CourseBadge";
import { LanguageBadge } from "../tooltip/LanguageBadge";

interface description {
  fileContent: string;
}

export const Flyout: React.FC = () => {
  let flyout;
  const dispatch = useDispatch();
  const flyoutVisible = useSelector(getFlyoutState);
  const flyoutData = useSelector(getFlyoutData);
  const setFlyoutClosed = () => {
    setMarkdownText("");
    dispatch(hideFlyout());
  };
  const [markdownText, setMarkdownText] = useState<string>(
    "Sorry, no description for this project just yet."
  );
  useEffect(() => {
    const showFile = async () => {
      if (flyoutData) {
        setMarkdownText(flyoutData.fields.description);
      }

      // FOR AWS S3 VERSION
      // if (flyoutData) {
      //   const result = await api.get<description>("/aws", {
      //     params: {
      //       key: flyoutData.full_description,
      //     },
      //   });
      //   setMarkdownText(result.data.fileContent);
      // }
    };

    showFile();
  }, [flyoutData]);

  if (flyoutVisible) {
    // @ts-ignore
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={() => setFlyoutClosed()}
        aria-labelledby="flyoutTitle"
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutTitle">
              {!flyoutData ? "" : flyoutData.fields.title}
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {markdownText === "" ? (
            <EuiLoadingContent lines={5} />
          ) : (
            <EuiText>
              {documentToReactComponents(
                // @ts-ignore
                flyoutData.fields.description,
                contentfulTextOptions
              )}
            </EuiText>
          )}
          {flyoutData &&
          flyoutData.fields.images &&
          flyoutData.fields.images.length > 0 ? (
            <ImageGallery project={flyoutData} />
          ) : null}

          {flyoutData &&
          flyoutData.fields.languages &&
          flyoutData.fields.languages.length > 0 ? (
            <>
              <EuiSpacer size={"s"} />
              <EuiMarkdownFormat>#### Programming Languages:</EuiMarkdownFormat>
              <EuiSpacer size={"s"} />
              <EuiFlexGroup gutterSize={"s"} justifyContent={"flexStart"}>
                {flyoutData.fields.languages.map((item, index) => {
                  return (
                    <EuiFlexItem grow={false}>
                      <LanguageBadge badge={item} popoverPosition={"bottom"} />
                    </EuiFlexItem>
                  );
                })}
              </EuiFlexGroup>
            </>
          ) : null}

          {flyoutData &&
          flyoutData.fields.course &&
          flyoutData.fields.course.length > 0 ? (
            <>
              <EuiSpacer size={"s"} />
              <EuiMarkdownFormat>#### Courses:</EuiMarkdownFormat>
              <EuiSpacer size={"s"} />
              <EuiFlexGroup gutterSize={"s"} justifyContent={"flexStart"}>
                <EuiFlexItem key={v4()} grow={false}>
                  {flyoutData.fields.course.map((item, index) => {
                    return (
                      <EuiFlexItem grow={false}>
                        <CourseBadge
                          // @ts-ignore
                          badge={item}
                          popoverPosition={"bottom"}
                        />
                      </EuiFlexItem>
                    );
                  })}
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          ) : null}
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }

  return <div>{flyout}</div>;
};
