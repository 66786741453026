import React from "react";
import { EuiListGroup, EuiListGroupItem, EuiSpacer } from "@elastic/eui";
import { v4 } from "uuid";
import { useDispatch } from "react-redux";
import { ProjectDto } from "../../api/projectSpaceApiTypes";
import { BADGE } from "../../api/projectSpaceApi";
import { AutoBadge } from "../../components/tooltip/AutoBadge";
import { showFlyout } from "../../features/redux/flyoutSlice";
import { ProjectsPerLanguageDto, ProjectsPerTagDto } from "./Tags";

interface ItemListProps {
  tagType: BADGE;
  items: (ProjectsPerLanguageDto | ProjectsPerTagDto)[];
}

interface TagListProps {
  tagType: BADGE;
  items: (ProjectsPerLanguageDto | ProjectsPerTagDto)[];
}

// @ts-ignore
export const TagList: React.FC<TagListProps> = ({ items, tagType }) => {
  const dispatch = useDispatch();
  const handleItemClick = async (item: ProjectDto) => {
    dispatch(showFlyout(item));
  };

  return items.map((item, index) => {
    return item.projects.length === 0 ? null : (
      <>
        <AutoBadge key={v4()} badge={item.type_} popoverPosition={"right"} />
        <EuiListGroup key={v4()} flush={false} bordered={false}>
          {item.projects.map((o, i) => {
            return (
              <EuiListGroupItem
                key={v4()}
                label={o.fields.title}
                onClick={() => {
                  handleItemClick(o);
                }}
              />
            );
          })}
        </EuiListGroup>
        <EuiSpacer key={v4()} />
      </>
    );
  });
};
// if (tagType === BADGE.LANGUAGE) {
//   return <ItemList items={projects} />;
// }
// if (tagType === BADGE.COURSE) {
//   return <ItemList items={tags.filter((o) => o.type !== "Language")} />;
// }
//
// if (tagType === BADGE.TAG) {
//   return <ItemList items={tags.filter((o) => o.type !== "Language")} />;
// }
// return null;
