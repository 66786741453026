import { EuiLink } from "@elastic/eui";
import React from "react";
import { showFlyout } from "../../features/redux/flyoutSlice";
import { useDispatch } from "react-redux";
import { ProjectDto } from "../../api/projectSpaceApiTypes";

interface LanguageBadgeProps {
  item: ProjectDto;
  text?: string;
}

export const ProjectLink: React.FC<LanguageBadgeProps> = ({ item, text }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(showFlyout(item));
  };

  return (
    <EuiLink>
      <span onClick={handleClick}>{text ? text : "View more."}</span>
    </EuiLink>
  );
};
