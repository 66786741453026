import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface ProjectsLoadedState {
  value: number;
}

const initialState: ProjectsLoadedState = {
  value: 9,
};

export const projectsLoadedSlice = createSlice({
  name: "projectsLoaded",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },

    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
});

export const { increment, incrementByAmount } = projectsLoadedSlice.actions;

export const getProjectsLoadedCount = (state: RootState) =>
  state.projectsLoaded.value;

export default projectsLoadedSlice.reducer;
