import { EuiButtonIcon, EuiFlexGroup, EuiPopover } from "@elastic/eui";
import React, { useState } from "react";
import { v4 } from "uuid";
import { AutoBadge } from "./AutoBadge";
import { CourseDto, LanguageDto, TagDto } from "../../api/projectSpaceApiTypes";

interface TagPopoverProps {
  badges: (LanguageDto | CourseDto | TagDto)[];
}

export const TagPopover: React.FC<TagPopoverProps> = ({ badges }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const onButtonClick = () => {
    setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
  };
  const closePopover = () => setIsPopoverOpen(false);

  const badge =
    badges === undefined
      ? null
      : badges.map((lang: LanguageDto | CourseDto | TagDto) => {
          return (
            <AutoBadge key={v4()} badge={lang} popoverPosition={"bottom"} />
          );
        });

  return (
    <EuiPopover
      button={
        <EuiButtonIcon
          iconType={"popout"}
          onClick={onButtonClick}
          color={"primary"}
          aria-label={"apps"}
        />
      }
      isOpen={isPopoverOpen}
      closePopover={closePopover}
    >
      <EuiFlexGroup
        alignItems="center"
        justifyContent="center"
        gutterSize={"xs"}
        wrap={true}
        style={{ maxWidth: 350 }}
        responsive={false}
      >
        {badge}
      </EuiFlexGroup>
    </EuiPopover>
  );
};
