import React, { useState } from "react";
import { EuiGlobalToastList } from "@elastic/eui";
import { v4 } from "uuid";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";

let addToastHandler: (t: Toast) => void;
let removeAllToastsHandler: { (): void; (): void };
let toastId = 0;

export function addToast(t: Toast) {
  if (t.id === "") {
    t.id = v4();
  }
  addToastHandler(t);
}

export function removeAllToasts(): void {
  removeAllToastsHandler();
}

export default () => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  addToastHandler = (t: Toast) => {
    setToasts(toasts.concat(t));
  };

  const removeToast = (removedToast: Toast) => {
    setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
  };

  removeAllToastsHandler = () => {
    setToasts([]);
  };

  return (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={removeToast}
      toastLifeTimeMs={6000}
    />
  );
};
