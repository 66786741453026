import {
  EuiFlexGroup,
  EuiHorizontalRule,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPanel,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import React from "react";
import { WorkDto } from "../../api/projectSpaceApiTypes";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { contentfulTextOptions } from "../../components/ContentfulText";
import moment from "moment";
import { v4 } from "uuid";

interface WorkProps {
  items: WorkDto[];
}

export const Work: React.FC<WorkProps> = ({ items }) => {
  return (
    <>
      <EuiPageContentHeader>
        <EuiPageContentHeaderSection>
          <EuiTitle>
            <h2>Experience</h2>
          </EuiTitle>
        </EuiPageContentHeaderSection>
      </EuiPageContentHeader>
      <EuiPanel>
        {items.map((i) => {
          return (
            <>
              {" "}
              <EuiFlexGroup gutterSize={"xs"}>
                <EuiText>
                  <h4 key={v4()}>
                    {i.fields.company.fields.name}
                    {" • "}
                    {i.fields.company.fields.city}
                    {", "}
                    {i.fields.company.fields.state}
                    <br />
                    {moment(i.fields.startDate).format("MMMM YYYY")}
                    {" - "}
                    {i.fields.endDate
                      ? moment(i.fields.endDate).format("MMMM YYYY")
                      : "Present"}
                  </h4>
                  <div style={{ paddingLeft: "2em" }} key={v4()}>
                    {documentToReactComponents(
                      // @ts-ignore
                      i.fields.description,
                      contentfulTextOptions
                    )}
                  </div>
                </EuiText>
              </EuiFlexGroup>
              <EuiHorizontalRule size="full" margin={"s"} />
            </>
          );
        })}
      </EuiPanel>
    </>
  );
};
