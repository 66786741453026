import React, { useState } from "react";
import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiSpacer,
} from "@elastic/eui";
import { ProjectDto } from "../../api/projectSpaceApiTypes";

interface ImageGalleryProps {
  project: ProjectDto;
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({ project }) => {
  const images = project.fields.images ? project.fields.images.slice() : null; //.sort((a, b) => a.priority - b.priority);
  const imageCount: number = images ? images.length : 0;
  const [imageIndex, setImageIndex] = useState<number>(0);

  const handlePreviousImageClick = () => {
    const newIndex = Math.max(0, imageIndex - 1);
    setImageIndex(newIndex);
  };

  const handleNextImageClick = () => {
    const newIndex = Math.min(imageCount - 1, imageIndex + 1);
    setImageIndex(newIndex);
  };

  return (
    <div>
      <EuiSpacer />
      <EuiFlexGroup alignItems={"center"} justifyContent="center">
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            iconType={"arrowLeft"}
            iconSize={"l"}
            onClick={handlePreviousImageClick}
            aria-label={"left arrow"}
            disabled={imageIndex === 0}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiImage
            size="l"
            hasShadow
            allowFullScreen
            caption={images ? images[imageIndex].fields.description : null}
            alt={images ? images[imageIndex].fields.description : ""}
            url={images ? images[imageIndex].fields.file.url : ""}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            iconType={"arrowRight"}
            iconSize={"l"}
            onClick={handleNextImageClick}
            aria-label={"right arrow"}
            disabled={imageIndex === imageCount - 1}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
