import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import { Header } from "../../components/Header";
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
} from "@elastic/eui";
import { projectSpaceApi } from "../../api/projectSpaceApi";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const ResumeRoute: React.FC = () => {
  const [resume, setResume] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      const result = await projectSpaceApi.getAsset("1cjbsIykswvVexybN2C7HV");
      setResume(result.fields.file.url);
    };
    fetchData();
  });
  return (
    <>
      <Header />
      <EuiPage>
        <EuiPageBody component="div">
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle size="l">
                <h1>Resume</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPageContent>
            <EuiPageContentBody>
              <iframe src={resume} width="100%" height="700" />
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};
