import React, { useEffect, useState } from "react";

import { EuiPage, EuiPageBody, EuiSpacer } from "@elastic/eui";
import { Header } from "../../components/Header";
import { Projects } from "./Projects";
import { Academics } from "./Academics";
import { LoadingPage } from "../../components/loading/LoadingPage";
import {
  fetchAboutMePromise,
  fetchProjectsPromise,
  fetchWorkPromise,
} from "../../api/projectSpaceApi";
import {
  AboutMeDto,
  ProjectDto,
  WorkDto,
} from "../../api/projectSpaceApiTypes";
import { AboutMe } from "./AboutMe";
import { Work } from "./Work";

export const IndexRoute: React.FC = () => {
  const [projects, setProjects] = useState<ProjectDto[]>([]);
  const [aboutMe, setAboutMe] = useState<AboutMeDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [work, setWork] = useState<WorkDto[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const projectsResult = await fetchProjectsPromise();
      const aboutMeResult = await fetchAboutMePromise();
      const workResult = await fetchWorkPromise();

      setAboutMe(aboutMeResult);
      setProjects(projectsResult);
      setWork(workResult);

      if (projectsResult && workResult) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <EuiPage>
        <EuiPageBody component="div">
          {isLoading ? (
            <LoadingPage />
          ) : (
            <>
              {aboutMe ? (
                <>
                  <AboutMe item={aboutMe} />
                  <EuiSpacer />
                </>
              ) : null}

              <Projects
                projects={projects}
                setProjects={setProjects}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
              <EuiSpacer />
              <Academics />
              {work ? (
                <>
                  <EuiSpacer />
                  <Work items={work} />
                </>
              ) : null}
            </>
          )}
        </EuiPageBody>
      </EuiPage>
    </>
  );
};
