import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialTheme: string = localStorage.getItem("theme")
  ? JSON.parse(<string>localStorage.getItem("theme"))
  : "light";

interface ThemeState {
  value: string;
}

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

const initialState: ThemeState = {
  value: initialTheme === "light" ? Theme.LIGHT : Theme.DARK,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setDarkTheme: (state) => {
      state.value = Theme.DARK;
      localStorage.setItem("theme", Theme.DARK);
      window.location.reload();
    },

    setLightTheme: (state) => {
      state.value = Theme.LIGHT;
      localStorage.setItem("theme", Theme.LIGHT);
      window.location.reload();
    },

    toggleTheme: (state) => {
      if (state.value === Theme.LIGHT) {
        state.value = Theme.DARK;
        localStorage.setItem("theme", JSON.stringify(Theme.DARK));
        window.location.reload();
      } else {
        state.value = Theme.LIGHT;
        localStorage.setItem("theme", JSON.stringify(Theme.LIGHT));
        window.location.reload();
      }
    },
  },
});

export const { setDarkTheme, setLightTheme, toggleTheme } = themeSlice.actions;

export const getThemeState = (state: RootState) => state.theme.value;

export default themeSlice.reducer;
