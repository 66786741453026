import React from "react";

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const ThemeDark = React.lazy(() => import("../themes/AmsterdamDark"));
const ThemeLight = React.lazy(() => import("../themes/AmsterdamLight"));

const ThemeSelector: React.FC = ({ children }) => {
  const currentTheme =
    JSON.parse(localStorage.getItem("theme") as string) === "dark"
      ? "dark"
      : "light";
  return (
    <>
      {/* Conditionally render theme, based on the current client context */}
      <React.Suspense fallback={() => null}>
        {currentTheme === "dark" && <ThemeDark />}
        {currentTheme === "light" && <ThemeLight />}
      </React.Suspense>
      {/* Render children immediately! */}
      {children}
    </>
  );
};

export default ThemeSelector;
