import { EuiComboBox, EuiComboBoxOptionOption } from "@elastic/eui";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  fetchComboBoxTagsPromise,
  fetchLinkedToCourse,
  fetchLinkedToLanguage,
  fetchLinkedToTag,
} from "../../api/projectSpaceApi";
import { ProjectDto } from "../../api/projectSpaceApiTypes";

var _ = require("lodash");

interface ProjectComboBoxProps {
  projects: ProjectDto[];
  setProjects: Dispatch<SetStateAction<ProjectDto[]>>;
}

export interface ComboBoxOptions extends EuiComboBoxOptionOption {
  id: string;
  itemType: string;
}

export const ProjectComboBox: React.FC<ProjectComboBoxProps> = ({
  projects,
  setProjects,
}) => {
  const [originalProjects, setOriginalProjects] =
    useState<ProjectDto[]>(projects);
  const [options, setOptions] = useState<ComboBoxOptions[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<ComboBoxOptions[]>([]);
  const [reloadOptions, setReloadOptions] = useState<boolean>(false);

  const onChange = (selectedOptions: ComboBoxOptions[]) => {
    setSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    const fetchData = async () => {
      let final;
      let langKeys: string = "";
      let courseKeys: string = "";
      let tagKeys: string = "";

      selectedOptions.forEach((item) => {
        if (item.itemType === "language") {
          langKeys += "," + item.id;
        } else if (item.itemType === "classes") {
          courseKeys += "," + item.id;
        } else if (item.itemType === "tags") {
          tagKeys += "," + item.id;
        }
      });

      const fetch = () => {
        return new Promise(async (resolve) => {
          let proj: ProjectDto[] = [];

          if (langKeys !== "") {
            const langResponse = await fetchLinkedToLanguage(langKeys);
            proj = proj.concat(langResponse.items);
          }

          if (courseKeys !== "") {
            const courseResponse = await fetchLinkedToCourse(courseKeys);
            proj = proj.concat(courseResponse.items);
          }
          if (tagKeys !== "") {
            const tagResponse = await fetchLinkedToTag(langKeys);
            proj = proj.concat(tagResponse.items);
          }

          resolve(proj);
        });
      };

      if (langKeys === "" && courseKeys === "" && tagKeys === "") {
        final = originalProjects;
      } else {
        final = await fetch();
        final = _.uniqBy(final, (i: ProjectDto) => i.sys.id);
      }
      setProjects(final);
    };

    fetchData();
  }, [selectedOptions]);

  useEffect(() => {
    const fetchData = async () => {
      const newOptions: ComboBoxOptions[] = await fetchComboBoxTagsPromise();
      setOptions(newOptions);
    };

    fetchData();
  }, [reloadOptions]);

  return (
    <EuiComboBox
      compressed={true}
      placeholder="Select tags"
      options={options}
      selectedOptions={selectedOptions}
      // @ts-ignore
      onChange={onChange}
    />
  );
};
