import { EuiBadge, EuiToolTip } from "@elastic/eui";
import React, { MouseEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../types/routes";
import { v4 } from "uuid";
import { ToolTipPositions } from "@elastic/eui/src/components/tool_tip/tool_tip";
import { hideFlyout } from "../../features/redux/flyoutSlice";
import { useDispatch } from "react-redux";
import { CourseDto } from "../../api/projectSpaceApiTypes";

interface CourseBadgeProps {
  badge: CourseDto;
  popoverPosition: ToolTipPositions;
}

export const CourseBadge: React.FC<CourseBadgeProps> = ({
  badge,
  popoverPosition,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const handleClick = (e: MouseEvent) => {
    dispatch(hideFlyout());
    if (location.pathname !== routes.TAGS) {
      history.push(routes.TAGS);
    }
  };
  if (badge === undefined) return null;
  return (
    <span>
      <EuiToolTip
        position={popoverPosition}
        content={
          <>
            <h5>
              {badge.fields.experience
                ? "Grade: " + badge.fields.experience
                : null}
            </h5>
            {badge.fields.description === null ? null : <br />}
            {badge.fields.description}
          </>
        }
      >
        <EuiBadge
          key={v4()}
          color={badge.fields.color}
          onClickAriaLabel="Click for more info"
          data-test-sub="click"
          onClick={(e: MouseEvent) => handleClick(e)}
        >
          {badge.fields.name}
        </EuiBadge>
      </EuiToolTip>
    </span>
  );
};
