import React from "react";
import "./App.css";
import { IndexRoute } from "./routes/Index";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { routes } from "./components/types/routes";
import { ContactRoute } from "./routes/Contact/Contact";
import { ResumeRoute } from "./routes/Resume/Resume";
import { TagsRoute } from "./routes/Tags/Tags";
import { GlobalToast } from "./components/toast";

const App: React.FC = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path={routes.INDEX} component={IndexRoute} />
          <Route path={routes.CONTACT} component={ContactRoute} />
          <Route path={routes.RESUME} component={ResumeRoute} />
          <Route path={routes.TAGS} component={TagsRoute} />
        </Switch>
      </Router>
      <GlobalToast />
    </>
  );
};

export default App;
