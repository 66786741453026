import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@elastic/eui";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Flyout } from "../../components/flyout/Flyout";
import { ProjectCards } from "./ProjectCards";
import { ProjectTable } from "./ProjectTable";
import { ProjectComboBox } from "./ProjectComboBox";
import { ProjectDto } from "../../api/projectSpaceApiTypes";

interface ProjectProps {
  projects: ProjectDto[];
  setProjects: Dispatch<SetStateAction<ProjectDto[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const Projects: React.FC<ProjectProps> = ({
  projects,
  setProjects,
  isLoading,
  setIsLoading,
}) => {
  const [showGrid, setShowGrid] = useState<boolean>(true);

  return (
    <>
      <EuiFlexGroup justifyContent={"flexEnd"}>
        <EuiFlexItem grow={7}>
          <EuiTitle>
            <h2>Projects</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          <EuiFlexGroup justifyContent={"flexEnd"} responsive={false}>
            <EuiFlexItem style={{ alignItems: "flex-end" }}>
              <ProjectComboBox projects={projects} setProjects={setProjects} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={showGrid ? "list" : "grid"}
                iconSize={"l"}
                aria-label={"list-or-grid-view"}
                onClick={() => setShowGrid(!showGrid)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      {/*<EuiSpacer size={"s"} />*/}
      {showGrid ? (
        <ProjectCards projects={projects} />
      ) : (
        <ProjectTable projects={projects} />
      )}
      <Flyout />
    </>
  );
};
