import { EuiLink, EuiToolTip } from "@elastic/eui";
import React from "react";
import { ToolTipPositions } from "@elastic/eui/src/components/tool_tip/tool_tip";
import { CourseDto, LanguageDto, TagDto } from "../../api/projectSpaceApiTypes";

interface TagTooltipProps {
  item: LanguageDto | CourseDto | TagDto;
  text: string;
  popoverPosition: ToolTipPositions;
}

export const TagTooltip: React.FC<TagTooltipProps> = ({
  item,
  text,
  popoverPosition,
}) => {
  return (
    <span>
      <EuiToolTip
        position={popoverPosition}
        content={
          <>{item.fields.description ? item.fields.description : null}</>
        }
      >
        <EuiLink href={"#"}>
          <span>{text}</span>
        </EuiLink>
      </EuiToolTip>
    </span>
  );
};
