import React from "react";
import { ToolTipPositions } from "@elastic/eui/src/components/tool_tip/tool_tip";
import { LanguageBadge } from "./LanguageBadge";
import { CourseBadge } from "./CourseBadge";
import { TagBadge } from "./TagBadge";
import { CourseDto, LanguageDto, TagDto } from "../../api/projectSpaceApiTypes";
import { EuiFlexItem } from "@elastic/eui";

interface AutoBadgeProps {
  badge: LanguageDto | CourseDto | TagDto;
  popoverPosition: ToolTipPositions;
}

export const AutoBadge: React.FC<AutoBadgeProps> = ({
  badge,
  popoverPosition,
}) => {
  const type = badge.sys.contentType.sys.id;

  if (type === "language") {
    return (
      <EuiFlexItem grow={false}>
        <LanguageBadge
          badge={badge as LanguageDto}
          popoverPosition={popoverPosition}
        />
      </EuiFlexItem>
    );
  }

  if (type === "classes") {
    return (
      <EuiFlexItem grow={false}>
        <CourseBadge
          badge={badge as CourseDto}
          popoverPosition={popoverPosition}
        />
      </EuiFlexItem>
    );
  }

  if (type === "tags") {
    return (
      <EuiFlexItem grow={false}>
        <TagBadge badge={badge as TagDto} popoverPosition={popoverPosition} />
      </EuiFlexItem>
    );
  }
  return null;
};
