import { LoadingIndicator } from "./LoadingIndicator";
import { EuiOverlayMask } from "@elastic/eui";
import React from "react";

export const LoadingPage: React.FC = () => {
  return (
    <EuiOverlayMask>
      <LoadingIndicator />
    </EuiOverlayMask>
  );
};
