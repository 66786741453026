import { createClient } from "contentful";
import {
  AboutMeDto,
  AboutMeDtoFields,
  CourseDto,
  CourseDtoFields,
  LanguageDto,
  LanguageDtoFields,
  ProjectDto,
  ProjectDtoFields,
  PublicationDto,
  PublicationDtoFields,
  TagDto,
  TagDtoFields,
  UniversityDto,
  UniversityDtoFields,
  WorkDto,
  WorkDtoFields,
} from "./projectSpaceApiTypes";
import { ComboBoxOptions } from "../routes/Index/ProjectComboBox";

export enum BADGE {
  LANGUAGE = "language",
  COURSE = "classes",
  TAG = "tags",
}

export const projectSpaceApi = createClient({
  space: "df8ikvh6mvgk",
  accessToken: "xwzau4S2avY1J95Mm5sKFOF7t5_Jhn-C23es6E8dZPA",
});

export const fetchProjectsPromise = () => {
  return new Promise<ProjectDto[]>(async (resolve) => {
    const result = await projectSpaceApi.getEntries<ProjectDtoFields>({
      content_type: "project",
      order: "fields.order",
    });

    const { items }: { items: ProjectDto[] } = result;
    resolve(items);
  });
};

export const fetchAboutMePromise = () => {
  return new Promise<AboutMeDto>(async (resolve) => {
    const result = await projectSpaceApi.getEntries<AboutMeDtoFields>({
      content_type: "aboutMe",
    });

    const { items }: { items: AboutMeDto[] } = result;
    resolve(items[0]);
  });
};

export const fetchWorkPromise = () => {
  return new Promise<WorkDto[]>(async (resolve) => {
    const result = await projectSpaceApi.getEntries<WorkDtoFields>({
      content_type: "work",
      include: 5,
      order: "-fields.startDate",
    });

    const { items }: { items: WorkDto[] } = result;
    resolve(items);
  });
};
export const fetchComboBoxTagsPromise = () => {
  return new Promise<ComboBoxOptions[]>(async (resolve) => {
    let newOptions: ComboBoxOptions[] = [];
    const langOptions = await projectSpaceApi.getEntries<LanguageDtoFields>({
      content_type: "language",
    });

    langOptions.items.forEach((e: LanguageDto) => {
      newOptions.push({
        label: e.fields.language,
        color: e.fields.color,
        id: e.sys.id,
        itemType: BADGE.LANGUAGE,
      });
    });

    const classOptions = await projectSpaceApi.getEntries<CourseDtoFields>({
      content_type: BADGE.COURSE,
    });
    classOptions.items.forEach((e: CourseDto) => {
      newOptions.push({
        label: e.fields.name,
        color: e.fields.color,
        id: e.sys.id,
        itemType: BADGE.COURSE,
      });
    });

    const tagOptions = await projectSpaceApi.getEntries<TagDtoFields>({
      content_type: BADGE.TAG,
    });
    tagOptions.items.forEach((e: TagDto) => {
      newOptions.push({
        label: e.fields.name,
        color: e.fields.color,
        id: e.sys.id,
        itemType: BADGE.TAG,
      });
    });

    resolve(newOptions);
  });
};

export const fetchLinkedToLanguage = async (keys: string) => {
  return await projectSpaceApi.getEntries<ProjectDtoFields>({
    content_type: "project",
    "fields.languages.sys.id[match]": keys,
  });
};

export const fetchLinkedToCourse = async (keys: string) => {
  return await projectSpaceApi.getEntries<ProjectDtoFields>({
    content_type: "project",
    "fields.course.sys.id[match]": keys,
  });
};

export const fetchLinkedToTag = async (keys: string) => {
  return await projectSpaceApi.getEntries<ProjectDtoFields>({
    content_type: "project",
    "fields.tags.sys.id[match]": keys,
  });
};

export const fetchPublicationsPromise = () => {
  return new Promise<PublicationDto[]>(async (resolve) => {
    const result = await projectSpaceApi.getEntries<PublicationDtoFields>({
      content_type: "publication",
    });

    const { items }: { items: PublicationDto[] } = result;
    resolve(items);
  });
};

export const fetchDegreesPromise = () => {
  return new Promise<UniversityDto[]>(async (resolve) => {
    const result = await projectSpaceApi.getEntries<UniversityDtoFields>({
      content_type: "education",
    });

    const { items }: { items: UniversityDto[] } = result;
    resolve(items);
  });
};
