import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPanel,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import React from "react";
import { AboutMeDto } from "../../api/projectSpaceApiTypes";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { contentfulTextOptions } from "../../components/ContentfulText";

interface AboutMeProps {
  item: AboutMeDto;
}

export const AboutMe: React.FC<AboutMeProps> = ({ item }) => {
  return (
    <>
      <EuiPageContentHeader>
        <EuiPageContentHeaderSection>
          <EuiTitle>
            <h2>About me</h2>
          </EuiTitle>
        </EuiPageContentHeaderSection>
      </EuiPageContentHeader>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiImage
              alt={"me"}
              url={item.fields.profilePicture.fields.file.url}
              size={"s"}
              style={{ borderRadius: "50%" }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              {documentToReactComponents(
                // @ts-ignore
                item.fields.description,
                contentfulTextOptions
              )}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </>
  );
};
