import React from "react";
import { EuiCode, EuiLink, EuiTitle } from "@elastic/eui";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ProjectLink } from "./tooltip/ProjectLink";
import { TagTooltip } from "./tooltip/TagTooltip";

interface optionProps {
  children: React.ReactNode;
}

const Bold: React.FC<optionProps> = ({ children }) => (
  <span className="bold">{children}</span>
);

const Italic: React.FC<optionProps> = ({ children }) => <i>{children}</i>;

const Underline: React.FC<optionProps> = ({ children }) => <u>{children}</u>;

const Text: React.FC<optionProps> = ({ children }) => (
  <p className="align-center">{children}</p>
);

const Code: React.FC<optionProps> = ({ children }) => (
  <EuiCode>{children}</EuiCode>
);

export const contentfulTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text: string) => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: (text: string) => <Underline>{text}</Underline>,
    [MARKS.CODE]: (text: string) => <Code>{text}</Code>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: React.FC, children: React.FC) => (
      <Text>{children}</Text>
    ),
    [BLOCKS.UL_LIST]: (node: React.FC, children: React.FC) => (
      <ul>{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (node: React.FC, children: React.FC) => {
      // @ts-ignore
      const UnTaggedChildren = documentToReactComponents(node, {
        renderMark: {
          [MARKS.CODE]: (text: string) => <Code>{text}</Code>,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => children,
          [BLOCKS.LIST_ITEM]: (node, children) => children,
          [INLINES.EMBEDDED_ENTRY]: (node: any) => {
            return (
              <TagTooltip
                text={node.data.target.fields.language}
                item={node.data.target}
                popoverPosition={"bottom"}
              />
            );
          },
          [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
            return <ProjectLink item={node.data.target} />;
          },
        },
      });

      return <li>{UnTaggedChildren}</li>;
    },
    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
      return (
        <EuiLink external={true} href={node.data.target.fields.file.url}>
          {children}
        </EuiLink>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      return <ProjectLink item={node.data.target} />;
    },

    [BLOCKS.HEADING_1]: (node: React.FC, children: React.FC) => (
      <EuiTitle size={"l"}>
        <h1>{children}</h1>
      </EuiTitle>
    ),

    [BLOCKS.HEADING_2]: (node: React.FC, children: React.FC) => (
      <EuiTitle size={"m"}>
        <h2>{children}</h2>
      </EuiTitle>
    ),

    [BLOCKS.HEADING_3]: (node: React.FC, children: React.FC) => (
      <EuiTitle size={"s"}>
        <h3>{children}</h3>
      </EuiTitle>
    ),

    [BLOCKS.HEADING_4]: (node: React.FC, children: React.FC) => (
      <EuiTitle size={"xs"}>
        <h4>{children}</h4>
      </EuiTitle>
    ),

    [BLOCKS.HEADING_5]: (node: React.FC, children: React.FC) => (
      <h5>{children}</h5>
    ),

    [BLOCKS.HEADING_6]: (node: React.FC, children: React.FC) => (
      <EuiTitle size={"xxs"}>
        <h6>{children}</h6>
      </EuiTitle>
    ),
  },
};
// <>
//   <AutoBadge popoverPosition={"bottom"} badge={node.data.target} />
//   {"ffffff "}
//   {children}
// </>

// renderText: (text: string) => {
//   return text
//     .split("\n")
//     .reduce((children: any[], textSegment: string, index: number) => {
//       return [...children, index > 0 && <br key={index} />, textSegment];
//     }, []);
// },
