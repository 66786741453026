import React from "react";
import { getProjectsLoadedCount } from "../../features/redux/projectsLoadedSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTableFieldDataColumnType,
} from "@elastic/eui";
import {
  InProductionTooltip,
  StarredTooltip,
} from "../../components/tooltip/tooltips";
import { showFlyout } from "../../features/redux/flyoutSlice";
import { LanguageBadge } from "../../components/tooltip/LanguageBadge";
import { CourseBadge } from "../../components/tooltip/CourseBadge";
import { TagBadge } from "../../components/tooltip/TagBadge";
import { ProjectDto } from "../../api/projectSpaceApiTypes";

interface ProjectTableProps {
  projects: ProjectDto[];
}

const columns: EuiTableFieldDataColumnType<ProjectDto>[] = [
  {
    field: "name",
    name: "Project",
    dataType: "string",
    truncateText: false,
    render: (tag, item) => {
      return (
        <EuiFlexGroup gutterSize={"s"} responsive={false} alignItems={"center"}>
          <EuiFlexItem>{item.fields.title}</EuiFlexItem>
          {item.fields.production ? <InProductionTooltip /> : null}
          {item.fields.starred ? <StarredTooltip /> : null}
        </EuiFlexGroup>
      );
    },
  },
  {
    field: "language",
    name: "Languages",
    render: (tag, item) => {
      const tags =
        item.fields.languages === undefined
          ? null
          : item.fields.languages.map((o) => {
              return (
                <EuiFlexItem grow={false}>
                  <LanguageBadge badge={o} popoverPosition={"bottom"} />
                </EuiFlexItem>
              );
            });
      return (
        <EuiFlexGroup
          justifyContent={"flexStart"}
          alignItems={"center"}
          gutterSize={"xs"}
          responsive={false}
        >
          {tags}
        </EuiFlexGroup>
      );
    },
  },
  {
    field: "course",
    name: "Courses",
    render: (tag, item) => {
      const tags =
        item.fields.course === undefined
          ? null
          : item.fields.course.map((o) => {
              return (
                <EuiFlexItem grow={false}>
                  <CourseBadge badge={o} popoverPosition={"bottom"} />
                </EuiFlexItem>
              );
            });
      return (
        <EuiFlexGroup
          justifyContent={"flexStart"}
          alignItems={"center"}
          gutterSize={"xs"}
          responsive={false}
        >
          {tags}
        </EuiFlexGroup>
      );
    },
  },

  {
    field: "tag",
    name: "Tags",
    render: (tag, item) => {
      const tags =
        item.fields.tags === undefined
          ? null
          : item.fields.tags.map((o) => {
              return (
                <EuiFlexItem grow={false}>
                  <TagBadge badge={o} popoverPosition={"bottom"} />
                </EuiFlexItem>
              );
            });
      return (
        <EuiFlexGroup
          justifyContent={"flexStart"}
          alignItems={"center"}
          gutterSize={"xs"}
          responsive={false}
        >
          {tags}
        </EuiFlexGroup>
      );
    },
  },
];

export const ProjectTable: React.FC<ProjectTableProps> = ({ projects }) => {
  const dispatch = useDispatch();
  const itemCount: number = useSelector(getProjectsLoadedCount);

  const getRowProps = (item: ProjectDto) => {
    const { id } = item.sys;
    return {
      "data-test-subj": `row-${id}`,
      className: "customRowClass",
      onClick: () => {
        dispatch(showFlyout(projects.find((o) => o.sys.id === item.sys.id)));
      },
    };
  };

  return (
    <EuiPanel>
      <EuiBasicTable
        items={projects}
        columns={columns}
        // tableLayout={"auto"}
        rowProps={getRowProps}
      />
    </EuiPanel>
  );
};
